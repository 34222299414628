// Structure
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/structure/general';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/structure/left-menu';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/structure/topbar';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/structure/right-sidebar';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/structure/page-head';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/structure/footer';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/structure/scrollbar';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/structure/announcement-banner';
// horizontal nav
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/structure/horizontal-nav';

//Fonts
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/fonts/nunito';

//Components
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/components/mixins';
//@import 'custom/components/accordions';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/components/avatar';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/components/breadcrumb';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/components/buttons';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/components/badge';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/components/card';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/components/datapicker';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/components/docs';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/components/forms';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/components/modal';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/components/nav';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/components/pagination';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/components/popover';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/components/print';
//@import 'custom/components/progress';
//@import 'custom/components/reboot';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/components/ribbons';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/components/switch';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/components/tables';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/components/type';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/components/timeline';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/components/utilities';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/components/primeng';
//@import 'custom/components/widgets';
//@import 'custom/components/social';
//@import 'custom/components/steps';
//@import 'custom/components/preloader';
//@import 'custom/components/hero';

//Pages
//@import 'custom/pages/authentication';
//@import 'custom/pages/components-demo';
//@import 'custom/pages/error';
//@import 'custom/pages/faq';
//@import 'custom/pages/maintenance';
//@import 'custom/pages/tasks';
//@import 'custom/pages/email';
//@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/pages/timeline';

// Vendors
//@import 'vendor/daterangepicker';
//@import 'vendor/bootstrap-datepicker.min';
//@import 'vendor/select2.min';
//@import 'vendor/jquery.toast.min';
//@import 'vendor/bootstrap-timepicker.min';
@import 'node_modules/ngx-bootstrap/datepicker/bs-datepicker.scss';

// Plugins
//@import 'custom/plugins/apexcharts';
//@import 'custom/plugins/britechart';
//@import 'custom/plugins/calendar';
//@import 'custom/plugins/chartjs';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/plugins/datatable';
//@import 'custom/plugins/daterange';
//@import 'custom/plugins/datepicker';
//@import 'custom/plugins/dragula';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/plugins/dropzone';
//@import 'custom/plugins/form-wizard';
//@import 'custom/plugins/maps';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/plugins/metis-menu';
//@import 'custom/plugins/select2';
//@import 'custom/plugins/slimscroll';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/plugins/toaster';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/plugins/bootstrap-touchspin';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/custom/plugins/bootstrap-timepicker';
//@import 'custom/plugins/summernote';
//@import 'custom/plugins/simplemde';
//@import 'custom/plugins/typehead';
//@import 'custom/plugins/sparklines';
//@import 'custom/plugins/rateit';
//@import 'custom/plugins/rangeslider';
//@import 'custom/plugins/frappe-gantt';

body {
  display: block;
}
